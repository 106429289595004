.ql-container {
  background-color: white !important;
}

#examDetails {
  padding: none !important;
}

.ql-editor {
  padding: 0px !important;
}

.ql-container {
  height: auto !important;
}
.ql-toolbar.ql-snow {
  display: block !important;
  position: fixed !important;
  z-index: 1000;
  background-color: white !important;
  top: 60px !important;
}

.circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 30px;
  border-radius: 50%;
}

.report-completed {
  box-shadow: 0 0 0 #fff, 0 0 2px rgba(46, 204, 113, 1),
    0 0 6px rgba(46, 204, 113, 1), 0 0 16px #fff, 0 0 10px rgba(46, 204, 113, 1),
    0 0 20px #fff, 0 0 28px rgba(46, 204, 113, 1);

  background-color: rgba(46, 204, 113, 1);
}

.report-in-progress {
  box-shadow: 0 0 0 #fff, 0 0 2px rgba(230, 126, 34, 1),
    0 0 6px rgba(230, 126, 34, 1), 0 0 16px #fff, 0 0 10px rgba(230, 126, 34, 1),
    0 0 20px #fff, 0 0 28px rgba(230, 126, 34, 1);
  background-color: rgba(230, 126, 34, 1);
}

.report-pending-validation {
  box-shadow: 0 0 0 #fff, 0 0 2px rgba(231, 76, 60, 1),
    0 0 6px rgba(231, 76, 60, 1), 0 0 16px #fff, 0 0 10px rgba(231, 76, 60, 1),
    0 0 20px #fff, 0 0 28px rgba(231, 76, 60, 1);
  background-color: rgba(231, 76, 60, 1);
}

.report-todo {
  box-shadow: 0 0 0 #fff, 0 0 2px rgba(52, 152, 219, 1),
    0 0 6px rgba(52, 152, 219, 1), 0 0 16px #fff, 0 0 10px rgba(52, 152, 219, 1),
    0 0 20px #fff, 0 0 28px rgba(52, 152, 219, 1);
  background-color: rgba(52, 152, 219, 1);
}

.active-circle-btn {
  background-color: #cdfdf1 !important;
  color: #2ecc71 !important;
}

.circle-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid white;
  color: black;
}
