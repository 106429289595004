.editor {
  height: 297mm;
  width: 210mm;
  box-shadow: 0 0 0 1px rgba(64, 87, 109, 0.07),
    0 2px 8px rgba(57, 76, 96, 0.15);
}

.custom-height {
  height: 100px;
}

.custom-page-number {
  border: 1px solid black;
  background-color: white;
  padding: 2px;
  font-size: 12px;
  z-index: 100;
  display: inline-block;
}

.custom-page-break {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
