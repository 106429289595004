.selectedRow {
  background-color: #e91e6330 !important;
}
.exam-discharge-requested {
  background-color: rgba(254, 202, 87, 1) !important;
}
.mo-discharge {
  background-color: rgba(254, 202, 87, 1) !important;
}

.mo-emergency {
  background-color: #f0654d !important;
}

.color-1 {
  border: 2px solid #f27827 !important;
}
.color-2 {
  border: 2px solid #819311 !important;
}
.color-3 {
  border: 2px solid #3f51b5 !important;
}
.color-4 {
  border: 2px solid #e91e63 !important;
}
.color-5 {
  border: 2px solid #f44336 !important;
}
.color-6 {
  border: 2px solid #00bcd4 !important;
}
.color-7 {
  border: 2px solid #009688 !important;
}
.color-8 {
  border: 2px solid #4caf50 !important;
}
.color-9 {
  border: 2px solid #8bc34a !important;
}
.color-10 {
  border: 2px solid #cddc39 !important;
  color: black !important;
}
.color-11 {
  border: 2px solid #ffeb3b !important;
}
.color-12 {
  border: 2px solid #ffc107 !important;
}
.color-13 {
  border: 2px solid #ff9800 !important;
}
.color-14 {
  border: 2px solid #ff5722 !important;
}
.color-15 {
  border: 2px solid #795548 !important;
}
.color-16 {
  border: 2px solid #9e9e9e !important;
}
.color-17 {
  border: 2px solid #607d8b !important;
}
.color-18 {
  border: 2px solid #673ab7 !important;
}
.color-19 {
  border: 2px solid #3e2723 !important;
}
.color-20 {
  border: 2px solid #1a237e !important;
}
.activity-indicator.color-1 {
  color: #f27827 !important;
}
.activity-indicator.color-2 {
  color: #819311 !important;
}
.activity-indicator.color-3 {
  color: #3f51b5 !important;
}
.activity-indicator.color-4 {
  color: #e91e63 !important;
}
.activity-indicator.color-5 {
  color: #f44336 !important;
}
.activity-indicator.color-6 {
  color: #00bcd4 !important;
}
.activity-indicator.color-7 {
  color: #009688 !important;
}
.activity-indicator.color-8 {
  color: #4caf50 !important;
}
.activity-indicator.color-9 {
  color: #8bc34a !important;
}
.activity-indicator.color-10 {
  color: black !important;
}
.activity-indicator.color-11 {
  color: #ffeb3b !important;
}
.activity-indicator.color-12 {
  color: #ffc107 !important;
}
.activity-indicator.color-13 {
  color: #ff9800 !important;
}
.activity-indicator.color-14 {
  color: #ff5722 !important;
}
.activity-indicator.color-15 {
  color: #795548 !important;
}
.activity-indicator.color-16 {
  color: black !important;
}
.activity-indicator.color-17 {
  color: #607d8b !important;
}
.activity-indicator.color-18 {
  color: #673ab7 !important;
}
.activity-indicator.color-19 {
  color: #3e2723 !important;
}
.activity-indicator.color-20 {
  color: #1a237e !important;
}
.activity-indicator {
  border: none !important;
}
.icon-wrapper {
  /* border-radius: 50% !important; */
  /* width: 20px !important; */
  /* height: 20px !important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 5px !important;
  border-width: 0px !important;
}

.blink-background {
  animation: blink-animation 1s linear infinite;
  -webkit-animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.15;
  }
}

@-webkit-keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.15;
  }
}
